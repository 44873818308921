import { EventEmitter } from '@angular/core';
import { EntityState } from '@ngrx/entity';
import { GgSearchParams } from './search-params.models';

export class GgPaginatedData<T> {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  data: T[];
}

export interface GgPaginatedState<T, SearchParams = GgSearchParams> extends EntityState<T> {
  searchParams: SearchParams;

  paginatedData: GgPaginatedData<T>;
}

export class GgPaginatedDataHandler<T> {
  searchParams: GgSearchParams;

  paginatedData?: GgPaginatedData<T>;

  searchParamsChanged: EventEmitter<GgSearchParams>;
}
