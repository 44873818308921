import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gg-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
