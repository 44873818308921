import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Invite,
  InvitePayload,
  InviteRegisteredUserPayload,
  LoadInvitationsParams,
  LoadInvitationsResponse,
  ResendInvitePayload,
} from '@core/models/invite.models';
import { SignInResponse } from '@core/models/sign-in.models';
import { GgHttpParams } from '@core/models/http.models';
import { Observable } from 'rxjs';

const FEATURE: string = 'invite';

@Injectable({ providedIn: 'root' })
export class InviteApiService {
  constructor(private readonly http: HttpClient) {}

  get(searchParams: LoadInvitationsParams): Observable<LoadInvitationsResponse> {
    const params: HttpParams = new HttpParams().appendAll(searchParams as GgHttpParams<LoadInvitationsParams>);

    return this.http.get<LoadInvitationsResponse>(`${FEATURE}/${searchParams.state}`, { params });
  }

  getOne(id: string): Observable<Invite> {
    return this.http.get<Invite>(`${FEATURE}/${id}`);
  }

  getByCompany(id: string): Observable<Invite> {
    return this.http.get<Invite>(`${FEATURE}/company/${id}`);
  }

  create(invite: InvitePayload): Observable<Invite> {
    return this.http.post<Invite>(FEATURE, invite);
  }

  sendToRegisteredUser(id: string, payload: InviteRegisteredUserPayload): Observable<Invite> {
    return this.http.post<Invite>(`${FEATURE}/${id}`, payload);
  }

  joinCompany(id: string): Observable<Invite> {
    return this.http.post<Invite>(`${FEATURE}/join-company/${id}`, {});
  }

  accept(id: string): Observable<Invite> {
    return this.http.post<Invite>(`${FEATURE}/accept/${id}`, {});
  }

  resend(id: string, payload: ResendInvitePayload = {}): Observable<Invite> {
    return this.http.post<Invite>(`${FEATURE}/resend/${id}`, payload);
  }

  decline(id: string): Observable<Invite> {
    return this.http.post<Invite>(`${FEATURE}/decline/${id}`, {});
  }

  cancel(id: string): Observable<Invite> {
    return this.http.post<Invite>(`${FEATURE}/cancel/${id}`, {});
  }

  authenticate(id: string): Observable<SignInResponse> {
    return this.http.get<SignInResponse>(`${FEATURE}/auth/${id}`);
  }
}
