import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {
  Article,
  ArticleCategory,
  LoadArticlesPayload,
  LoadArticlesResponse,
  ShareArticlePayload,
  ShareArticleResponse,
} from '@core/models/article.models';
import { Observable } from 'rxjs';
import { GgHttpParams } from '@core/models/http.models';
import { GgPaginatedData } from '@core/models/paginated-data.models';
import { GgSearchParams } from '@core/models/search-params.models';
import { HttpApiService } from './http-api.service';

const FEATURE: string = 'article';

@Injectable({ providedIn: 'root' })
export class ArticleApiService {
  constructor(private readonly httpApi: HttpApiService) {}

  get(searchParams: GgHttpParams<LoadArticlesPayload>): Observable<LoadArticlesResponse> {
    const params: HttpParams = new HttpParams().appendAll(searchParams);

    return this.httpApi.get<LoadArticlesResponse>(FEATURE, { params });
  }

  getOne(id: string): Observable<Article> {
    return this.httpApi.get<Article>(`${FEATURE}/${id}`);
  }

  getCategories(searchParams: GgHttpParams<GgSearchParams>): Observable<GgPaginatedData<ArticleCategory>> {
    const params: HttpParams = new HttpParams().appendAll(searchParams);

    return this.httpApi.get<GgPaginatedData<ArticleCategory>>(`${FEATURE}/tag`, { params });
  }

  share(payload: ShareArticlePayload): Observable<ShareArticleResponse> {
    return this.httpApi.post<ShareArticleResponse>(FEATURE, payload);
  }
}
