import { Type } from '@angular/core';
import { ClientsIconComponent } from './clients-icon/clients-icon.component';
import { WellnessOfferingsIconComponent } from './wellness-offerings-icon/wellness-offerings-icon.component';
import { SurveysIconComponent } from './surveys-icon/surveys-icon.component';
import { ContentLibraryIconComponent } from './content-library-icon/content-library-icon.component';
import { RecommendationsIconComponent } from './recommendations-icon/recommendations-icon.component';
import { InstructorsIconComponent } from './instructors-icon/instructors-icon.component';
import { MarketingEmailIconComponent } from './marketing-email-icon/marketing-email-icon.component';
import { AccountSettingsIconComponent } from './account-settings-icon/account-settings-icon.component';
import { LogoutIconComponent } from './logout-icon/logout-icon.component';
import { ArrowBackIconComponent } from './arrow-back-icon/arrow-back-icon.component';
import { DashboardBannerLogoIconComponent } from './dashboard-banner-logo-icon/dashboard-banner-logo-icon.component';
import { ChevronRightIconComponent } from './chevron-right-icon/chevron-right-icon.component';
import { CloseIconComponent } from './close-icon/close-icon.component';
import { SearchIconComponent } from './search-icon/search-icon.component';
import { CardIconComponent } from './card-icon/card-icon.component';
import { AchIconComponent } from './ach-icon/ach-icon.component';
import { DeleteIconComponent } from './delete-icon/delete-icon.component';
import { EditIconComponent } from './edit-icon/edit-icon.component';
import { ChevronDownIconComponent } from './chevron-down-icon/chevron-down-icon.component';
import { InfoIconComponent } from './info-icon/info-icon.component';
import { DatepickerIconComponent } from './datepicker-icon/datepicker-icon.component';
import { ChevronLeftIconComponent } from './chevron-left-icon/chevron-left-icon.component';
import { EngagementIconComponent } from './engagement-icon/engagement-icon.component';
import { DownloadIconComponent } from './download-icon/download-icon.component';
import { ContactIconComponent } from './contact-icon/contact-icon.component';
import { SmWhiteLogoIconComponent } from './sm-white-logo-icon/sm-white-logo-icon.component';
import { ActivityCalendarIconComponent } from './activity-calendar-icon/activity-calendar-icon.component';
import { CalendarIconComponent } from './calendar-icon/calendar-icon.component';
import { BookServicesIconComponent } from './book-services-icon/book-services-icon.component';
import { CouponIconComponent } from './coupon-icon/coupon-icon.component';
import { RatingStarIconComponent } from './rating-star-icon/rating-star-icon.component';
import { VideoLibraryIconComponent } from './video-library-icon/video-library-icon.component';

export * from './clients-icon/clients-icon.component';
export * from './wellness-offerings-icon/wellness-offerings-icon.component';
export * from './surveys-icon/surveys-icon.component';
export * from './content-library-icon/content-library-icon.component';
export * from './recommendations-icon/recommendations-icon.component';
export * from './instructors-icon/instructors-icon.component';
export * from './marketing-email-icon/marketing-email-icon.component';
export * from './account-settings-icon/account-settings-icon.component';
export * from './logout-icon/logout-icon.component';
export * from './arrow-back-icon/arrow-back-icon.component';
export * from './dashboard-banner-logo-icon/dashboard-banner-logo-icon.component';
export * from './chevron-right-icon/chevron-right-icon.component';
export * from './close-icon/close-icon.component';
export * from './search-icon/search-icon.component';
export * from './card-icon/card-icon.component';
export * from './ach-icon/ach-icon.component';
export * from './delete-icon/delete-icon.component';
export * from './edit-icon/edit-icon.component';
export * from './chevron-down-icon/chevron-down-icon.component';
export * from './video-library-icon/video-library-icon.component';
export * from './info-icon/info-icon.component';
export * from './datepicker-icon/datepicker-icon.component';
export * from './chevron-left-icon/chevron-left-icon.component';
export * from './engagement-icon/engagement-icon.component';
export * from './download-icon/download-icon.component';
export * from './contact-icon/contact-icon.component';
export * from './sm-white-logo-icon/sm-white-logo-icon.component';
export * from './activity-calendar-icon/activity-calendar-icon.component';
export * from './calendar-icon/calendar-icon.component';
export * from './book-services-icon/book-services-icon.component';
export * from './coupon-icon/coupon-icon.component';
export * from './rating-star-icon/rating-star-icon.component';

export const components: Array<Type<any>> = [
  ClientsIconComponent,
  WellnessOfferingsIconComponent,
  SurveysIconComponent,
  ContentLibraryIconComponent,
  RecommendationsIconComponent,
  InstructorsIconComponent,
  VideoLibraryIconComponent,
  MarketingEmailIconComponent,
  AccountSettingsIconComponent,
  LogoutIconComponent,
  ArrowBackIconComponent,
  DashboardBannerLogoIconComponent,
  ChevronRightIconComponent,
  CloseIconComponent,
  SearchIconComponent,
  CardIconComponent,
  AchIconComponent,
  DeleteIconComponent,
  EditIconComponent,
  ChevronDownIconComponent,
  InfoIconComponent,
  DatepickerIconComponent,
  ChevronLeftIconComponent,
  EngagementIconComponent,
  DownloadIconComponent,
  ContactIconComponent,
  SmWhiteLogoIconComponent,
  ActivityCalendarIconComponent,
  CalendarIconComponent,
  BookServicesIconComponent,
  CouponIconComponent,
  RatingStarIconComponent,
];
