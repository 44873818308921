import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable, of, switchMap, take } from 'rxjs';
import { Action, ActionCreator, MemoizedSelector, Store } from '@ngrx/store';
import { GgSearchParams } from '@core/models/search-params.models';

export interface LoadPaginatedDataAction<T = GgSearchParams> extends Action {
  payload?: Partial<T>;
}

export class BaseEffect<S = unknown> {
  constructor(public store?: Store<S>) {}

  handleResponse(
    successAction: ActionCreator,
    failureAction: ActionCreator
  ): <T>(source: Observable<T>) => Observable<any> {
    return <T>(source: Observable<T>): Observable<any> => {
      return source
        .pipe(map((response: T) => successAction({ response })))
        .pipe(catchError(({ error }: HttpErrorResponse) => of(failureAction({ error }))));
    };
  }

  mapSearchParams<T>(
    selector: MemoizedSelector<S, T>
  ): (source: Observable<LoadPaginatedDataAction<T>>) => Observable<T> {
    return (source: Observable<LoadPaginatedDataAction<T>>): Observable<T> => {
      if (!(this.store instanceof Store)) {
        throw new Error('Please provide store instance.');
      }

      return source.pipe(
        switchMap(({ payload: params }: LoadPaginatedDataAction<T>) =>
          this.store
            .select(selector)
            .pipe(map((searchParams: T) => ({ ...searchParams, ...(params || {}) })))
            .pipe(take(1))
        )
      );
    };
  }
}
