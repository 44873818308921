import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { AuthState, initialState } from './auth.state';
import { omit } from 'lodash';

import * as authActions from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

const successfullyAuthorized = (state, action): AuthState => {
  return { ...state, ...omit(action.response, ['user']) };
};

export const authReducer: ActionReducer<AuthState, Action> = createReducer(
  initialState,

  on(authActions.signUpSuccess, successfullyAuthorized),

  on(authActions.signInSuccess, successfullyAuthorized),

  on(authActions.authenticateSuccess, successfullyAuthorized),

  on(authActions.refreshTokenSuccess, (state, action) => {
    return { ...state, ...action.response, refreshToken: null };
  }),

  on(authActions.logOut, (state) => {
    return { ...state, accessToken: null, refreshToken: null };
  })
);
