import { HttpClient, HttpParams } from '@angular/common/http';
import { Class, ClassPayload, LoadClassesResponse, ShareClass } from '@core/models/class.models';
import { GgSearchParams } from '@core/models/search-params.models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const FEATURE: string = 'class';

@Injectable({ providedIn: 'root' })
export class ClassApiService {
  constructor(private readonly http: HttpClient) {}

  get(searchParams: GgSearchParams): Observable<LoadClassesResponse> {
    const queryParams: HttpParams = new HttpParams().appendAll(searchParams as any);

    return this.http.get<LoadClassesResponse>(FEATURE, { params: queryParams });
  }

  getOne(id: string): Observable<Class> {
    return this.http.get<Class>(`${FEATURE}/${id}`);
  }

  create(payload: ClassPayload): Observable<Class> {
    const formData: FormData = this.transformToFormDataPayload(payload);
    return this.http.post<Class>(FEATURE, formData);
  }

  patch(payload: ClassPayload): Observable<Class> {
    const formData: FormData = this.transformToFormDataPayload(payload);
    return this.http.patch<Class>(FEATURE, formData);
  }

  delete(id: string): Observable<Class> {
    return this.http.delete<Class>(`${FEATURE}/${id}`);
  }

  share(payload: ShareClass): Observable<unknown> {
    return this.http.post<unknown>(`${FEATURE}/share`, payload);
  }

  private transformToFormDataPayload(payload: ClassPayload): FormData {
    let formData: FormData = new FormData();

    for (let key in payload) {
      let value: string | Blob = payload[key]
      const isBlob = value instanceof Blob;
      if (typeof value === 'object' && value !== null && !isBlob) {
        if (Array.isArray(value)) {
          for(let aKey in value) {
            formData.append(`${key}[]`, value[aKey]);
          }
        } else {
          formData.append(key, JSON.stringify(value));
        }
      } else {
        formData.append(key, value);
      }
    }

    return formData;
  }
}
