import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { RootStoreModule } from '@store/root-store.module';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@environment';
import { UntypedFormBuilder } from '@angular/forms';
import { GgFormBuilder } from '@core/models/forms.models';
import { NgxMaskModule } from 'ngx-mask';
import { APP_EVENTS } from '@core/constants/app-events.constants';
import { AppEventsApiService } from '@core/services/api/app-events-api.service';
import { CustomScrollbar } from '@core/providers/app.providers';
import { VersionRendererComponent } from '@shared/components/version-renderer/version-renderer.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    RootStoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    VersionRendererComponent,
    NgxStripeModule.forRoot(environment.stripeKey),
    NgxMaskModule.forRoot({}),
  ],
  providers: [
    CustomScrollbar,
    { provide: UntypedFormBuilder, useClass: GgFormBuilder },
    { provide: APP_EVENTS, useClass: AppEventsApiService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
