import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, switchMap, tap } from 'rxjs';

import * as fromActions from './classes.actions';
import * as fromSelectors from './classes.selectors';

import { ClassApiService } from '@core/services/api/class-api.service';
import { ClassesState } from '.';
import { GgSearchParams } from '@core/models/search-params.models';
import { BaseEffect } from '@store/base/base.models';

@Injectable()
export class ClassesEffects extends BaseEffect<ClassesState> {
  constructor(
    private readonly actions$: Actions,
    private readonly classApi: ClassApiService,
    public readonly store: Store<ClassesState>
  ) {
    super(store);
  }

  loadClasses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadClasses),
      this.mapSearchParams(fromSelectors.selectSearchParams),
      switchMap((searchParams: GgSearchParams) =>
        this.classApi
          .get(searchParams)
          .pipe(this.handleResponse(fromActions.loadClassesSuccess, fromActions.loadClassesFailure))
      )
    )
  );

  loadMoreClasses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMoreClasses),
      this.mapSearchParams(fromSelectors.selectSearchParams),
      switchMap((searchParams: GgSearchParams) =>
        this.classApi
          .get(searchParams)
          .pipe(this.handleResponse(fromActions.loadMoreClassesSuccess, fromActions.loadMoreClassesFailure))
      )
    )
  );

  createClass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createClass),
      switchMap(({ payload }) =>
        this.classApi
          .create(payload)
          .pipe(this.handleResponse(fromActions.createClassSuccess, fromActions.createClassFailure))
      )
    )
  );

  patchClass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.patchClass),
      switchMap(({ payload }) =>
        this.classApi
          .patch(payload)
          .pipe(this.handleResponse(fromActions.patchClassSuccess, fromActions.patchClassFailure))
      )
    )
  );

  deleteClass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteClass),
      switchMap(({ payload }) =>
        this.classApi
          .delete(payload)
          .pipe(this.handleResponse(fromActions.deleteClassSuccess, fromActions.deleteClassFailure))
          .pipe(tap(() => this.store.dispatch(fromActions.loadClasses({}))))
      )
    )
  );
}
