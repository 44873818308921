import { Observable } from 'rxjs';
import { GgSelectOption } from './option.models';
import { GgPaginatedData } from './paginated-data.models';

export class GgSearchParams {
  query: string;
  page: number;
  limit: number;
  area?: string[];
}

export type GgSearchMethodFn = (params: GgSearchParams) => Observable<GgPaginatedData<GgSelectOption>>;
