import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf, Type } from '@angular/core';
import { MaterialModule } from '@material/material.module';
import { ApiTokenInterceptor } from './services/interceptors/api-token.interceptor';
import { ApiUrlnterceptor } from './services/interceptors/api-url.interceptor';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';

@NgModule({
  imports: [CommonModule, MaterialModule, HttpClientModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiUrlnterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
