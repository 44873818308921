import { CreateStructuralRouteFn, StructuralRoute } from '@core/models/route.models';

const createStructuralRoute: CreateStructuralRouteFn = (
  prefix: string = '',
  suffix: string = '',
  child?: StructuralRoute,
  paramName: string = 'id'
): StructuralRoute => {
  return {
    prefix,
    suffix,

    get route() {
      const prefix: string = `${this.prefix ? `${this.prefix}/` : ''}`;
      const suffix: string = `${this.suffix ? `/${this.suffix}` : ''}`;
      const _route: string = `${prefix}:${paramName}${suffix}`;
      return child ? `${_route}/${child.route}` : _route;
    },

    construct(id: string, prefixUrl: string = '') {
      const _route: string = `${this.prefix ? `${this.prefix}/` : ''}${id}${this.suffix ? `/${this.suffix}` : ''}`;
      const currentUrl: string = `${prefixUrl ? `${prefixUrl}/` : ''}${_route}`;
      return child ? (id: string) => child.construct(id, currentUrl) : currentUrl;
    },
  };
};

export const APP_ROUTE_CONFIG = {
  root: '',
  children: {
    notFound: 'not-found',
  },
};

export const SIGN_UP_ROUTE_CONFIG = {
  root: 'sign-up',
  children: {
    intro: 'intro',
    details: 'details',
    billing: 'billing',
    summary: 'summary',
    setPassword: 'set-password',
  },
};

export const AUTH_ROUTE_CONFIG = {
  root: 'auth',
  children: {
    logIn: 'log-in',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
  },
};

export const ASSIGNMENT_ROUTE_CONFIG = {
  root: 'assignment',
  children: {},
};

export const ACCOUNTS_ROUTE_CONFIG = {
  root: 'accounts',
  children: {
    list: '',
    view: createStructuralRoute(),
    userDetails: createStructuralRoute(null, 'user-details'),
  },
};

export const RECOMMENDATIONS_ROUTE_CONFIG = {
  root: 'recomendations',
  children: {
    list: '',
    view: createStructuralRoute('view'),
    addClasses: createStructuralRoute('add-classes'),
    addArticle: createStructuralRoute('add-article'),
    featuredProgram: createStructuralRoute('featured-program'),
  },
};

export const WELLNESS_OFFERINGS_ROUTE_CONFIG = {
  root: 'wellness-offerings',
  children: {
    view: '',
    onDemand: 'on-demand',
    viewClass: createStructuralRoute(null, 'view', null, 'classId'),
    byCategory: createStructuralRoute('category'),
    viewClassByCategory: createStructuralRoute('category', null, createStructuralRoute('class', null, null, 'classId')),
  },
};

export const CONTENT_LIBRARY_ROUTE_CONFIG = {
  root: 'content-library',
  children: {
    view: createStructuralRoute(),
  },
};

export const ACCOUNT_SETTINGS_ROUTE_CONFIG = {
  root: 'account-settings',
  children: {
    view: '',
    invitations: 'invitations',
    previousCharges: 'previous-charges',
  },
};

export const INSTRUCTORS_ROUTE_CONFIG = {
  root: 'instructors',
  children: {},
};

export const SURVEYS_ROUTE_CONFIG = {
  root: 'surveys',
  children: {
    view: '',
  },
};

export const CLIENT_ROUTE_CONFIG = {
  root: 'client',
  children: {
    view: createStructuralRoute(),
    details: createStructuralRoute(null, 'details'),
    featuredProgram: createStructuralRoute(null, 'featured-program'),
    featuredProgramClass: createStructuralRoute(
      null,
      'featured-program',
      createStructuralRoute('class', null, null, 'classId')
    ),
  },
};

export const COUPONS_ROUTE_CONFIG = {
  root: 'coupons',
  children: {
    list: '',
  },
};

export const OFFICE_ROUTE_CONFIG = {
  root: 'office',
  children: {
    view: createStructuralRoute(),
    edit: createStructuralRoute(null, 'edit'),
    featuredProgram: createStructuralRoute(null, 'featured-program'),
    featuredProgramClass: createStructuralRoute(
      null,
      'featured-program',
      createStructuralRoute('class', null, null, 'classId')
    ),
  },
};

export const ON_DEMAND_ROUTE_CONFIG = {
  root: 'on-demand',
  children: {
    view: '',
  },
};

export const ON_DEMAND_STATS_ROUTE_CONFIG = {
  root: 'on-demand-stats',
  children: {
    view: '',
  },
};

export const ENGAGEMENT_STATS_ROUTE_CONFIG = {
  root: 'engagement-stats',
  children: {
    view: '',
  },
};

export const DOWNLOADABLE_PDFS_ROUTE_CONFIG = {
  root: 'downloadable-pdfs',
  children: { view: '' },
};

export const AUTH_CONTENT_ROUTE_CONFIG = {
  root: '',
  children: {
    accounts: ACCOUNTS_ROUTE_CONFIG,
    engagementStats: ENGAGEMENT_STATS_ROUTE_CONFIG,
    recomendations: RECOMMENDATIONS_ROUTE_CONFIG,
    wellnessOfferings: WELLNESS_OFFERINGS_ROUTE_CONFIG,
    contentLibrary: CONTENT_LIBRARY_ROUTE_CONFIG,
    accountSettings: ACCOUNT_SETTINGS_ROUTE_CONFIG,
    instructors: INSTRUCTORS_ROUTE_CONFIG,
    onDemand: ON_DEMAND_ROUTE_CONFIG,
    onDemandStats: ON_DEMAND_STATS_ROUTE_CONFIG,
    downloadablePdfs: DOWNLOADABLE_PDFS_ROUTE_CONFIG,
    coupons: COUPONS_ROUTE_CONFIG,
    surveys: SURVEYS_ROUTE_CONFIG,
    client: CLIENT_ROUTE_CONFIG,
    office: OFFICE_ROUTE_CONFIG,
  },
};
