import { GgSearchParams } from '@core/models/search-params.models';
import { GgPaginatedData } from '@core/models/paginated-data.models';
import { BaseEntity } from './base-entity.models';
import { GgBaseOption } from './option.models';
import { GgFile } from './file.models';

export enum ClassType {
  ONSITE = 'ON-SITE',
  VIRTUAL = 'Virtual',
}

export enum ClassDuration {
  QUARTER_HOUR = '15',
  HALF_HOUR = '30',
  THREE_QUARTERS_OF_HOUR = '45',
  HOUR = '60',
}

export interface Class<OptionType = GgBaseOption> extends BaseEntity {
  title: string;
  type: ClassType[];
  class_type: OptionType;
  content_type: OptionType;
  duration: number;
  area: OptionType;
  is_free?: boolean;
  description: string;
  non_bookable: number;
  picture: GgFile;
  instructor?: OptionType;
  durations: Record<ClassDuration, number>;
  is_deleted: boolean;
}

export class ClassSearchParams extends GgSearchParams {
  area?: string[];
  type?: string[];
  class_type?: string[];
  on_demand?: boolean;
  instructor?: string;
  exclude_non_bookable?: number;
}

export class ClassTypeSearchParams extends GgSearchParams {
  area?: string[];
}

export class LoadClassesResponse extends GgPaginatedData<Class> {}

export interface ClassPayload extends Omit<Class<string>, keyof BaseEntity | 'is_deleted'> {
  id?: string;
}

export interface ShareClass {
  id: string;
  client_ids: string[];
}
